<template>
  <div>
    <!--
    {{bookingWorkspace}}
    -->
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('app',['locale', 'bookingWorkspace']),
  },
}
</script>

